import React from "react";
import _ from "lodash";
import { fromJS } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import FormAutoComplete from "./FormAutoComplete";
import { phoneCountyCodeList } from "../../constants/PhoneCountryCodes";

const enhancer = compose();

FormCountyPhoneCodeField.propTypes = {
  name: PropTypes.string.isRequired,

  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  maxSearchResults: PropTypes.number,

  focus: PropTypes.bool,

  hintText: PropTypes.node,
  label: PropTypes.node,
};

FormCountyPhoneCodeField.defaultProps = {
  maxSearchResults: 10,
};
// const getCode = /\(([^)]+)\)/;

// const parseInput = country => getCode.exec(country);
const parseInput = country => ({ country });
const formatOption = x => {
  const code =
    x && x.code ? (x.code.startsWith("+") ? x.code : `+${x.code}`) : null;

  if (x && !code) {
    return x.country;
  }

  if (x && code) {
    if (x.country) {
      return `${x.country} (${code})`;
    }
    const countryFromCode = fromJS(_.find(phoneCountyCodeList, { code }));
    return `${countryFromCode.country} (${code})`;
  }

  return null;
};

function FormCountyPhoneCodeField(props) {
  return (
    <FormAutoComplete
      {...props}
      parseInput={parseInput}
      formatOption={formatOption}
      options={phoneCountyCodeList}
    />
  );
}

export default enhancer(FormCountyPhoneCodeField);
