import { api } from "./BaseApi";
import { API_V2_ROOT_URL } from "../../../shared/constants/ApiConstants";

const VOLUMETRIC_WEIGHT_URL = `${API_V2_ROOT_URL}/packages/volumetric_weight`;
const CHARGEABLE_WEIGHT_URL = `${API_V2_ROOT_URL}/packages/chargeable_weight`;

export const getVolumetricWeight = request =>
  api.getStream(VOLUMETRIC_WEIGHT_URL, { params: request.getDefinedValues() });

export const getChargeableWeight = request =>
  api.getStream(CHARGEABLE_WEIGHT_URL, { params: request.getDefinedValues() });
