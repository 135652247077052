import React from "react";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Dialog, DialogContent, CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import Text from "../ui-core/Text";
import FlexBox from "../ui-core/FlexBox";
import { FirebaseSDK } from "../../firebase/FirebaseSDK";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({ root: { maxWidth: "320px" } }),
  mapPropsStream((propsStream) => {
    const db = new FirebaseSDK();

    return propsStream.combineLatest(db.isOnline(), (props, isOnline) => ({
      ...props,
      isOnline,
    }));
  }),
);

FirebaseOfflineDialog.propTypes = {
  isOnline: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
};

function FirebaseOfflineDialog(props) {
  const { getLocalisationMessage } = props;

  return (
    <Dialog open={!props.isOnline}>
      <DialogContent>
        <FlexBox align="center" direction="column">
          <Text element="p" type="align-center">
            {getLocalisationMessage(
              "connection_with_server_is_lost",
              "Connection With Server Is Lost",
            )}
            <br />
            {getLocalisationMessage(
              "trying_to_reconnect",
              "Trying To Reconnect",
            )}
          </Text>

          <CircularProgress color="secondary" />
        </FlexBox>
      </DialogContent>
    </Dialog>
  );
}

export default enhancer(FirebaseOfflineDialog);
