import _ from "lodash";
import { Map, List } from "immutable";
import fp from "lodash/fp";
import { getSize, getValue } from "./DataUtils";

type LatLng = { lat: number, lng: number };

type LatLon = { lat: number, lng: number };

type Point = LatLng | LatLon | Map<LatLng> | Map<LatLon>;
type PointList = Point[] | List<Point>;

export const toDegree = (rad: number): number =>
  _.isFinite(rad) ? (rad * 180) / Math.PI : null;
export const toRadians = (deg: number): number =>
  _.isFinite(deg) ? deg * (Math.PI / 180) : null;

export function computeDistance(from: Point, to: Point): number {
  const R = 6371;

  // Earth radius.
  const toLat = getValue(to, "lat");
  const toLng = getValue(to, "lng", getValue(to, "lon"));
  const fromLat = getValue(from, "lat");
  const fromLng = getValue(from, "lng", getValue(from, "lon"));

  const latDistance = toRadians(toLat - fromLat);
  const lngDistance = toRadians(toLng - fromLng);

  const a =
    Math.sin(latDistance / 2) * Math.sin(latDistance / 2) +
    Math.cos(toRadians(fromLat)) *
      Math.cos(toRadians(toLat)) *
      Math.sin(lngDistance / 2) *
      Math.sin(lngDistance / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c * 1000;
}

export function computeCenter(locations: PointList): LatLng {
  const size = getSize(locations);
  let sumX = 0;
  let sumY = 0;
  let sumZ = 0;

  for (let i = 0; i < size; i++) {
    const item = getValue(locations, i);
    const lat = toRadians(getValue(item, "lat"));
    const lng = toRadians(getValue(item, "lng", getValue(item, "lon")));

    sumX += Math.cos(lat) * Math.cos(lng);
    sumY += Math.cos(lat) * Math.sin(lng);
    sumZ += Math.sin(lat);
  }

  const avgX = sumX / size;
  const avgY = sumY / size;
  const avgZ = sumZ / size;

  // Convert average x, y, z coordinate to latitude and longitude.

  const lng = Math.atan2(avgY, avgX);
  const lat = Math.atan2(avgZ, Math.sqrt(avgX * avgX + avgY * avgY));

  return { lat: toDegree(lat), lng: toDegree(lng) };
}

export const isGeneratedAddressFormat = fp.flow(
  fp.over([
    fp.includes("Apt: "),
    fp.includes("/Bldg: "),
    fp.includes("/Str: "),
  ]),
  fp.some(Boolean),
);

export const getProperGeoAddressString = address => {
  if (isGeneratedAddressFormat(address)) {
    return "";
  }
  return address;
};
export const getProperGeoAddressObj = addressObj => {
  if (addressObj) {
    if (addressObj.geo_address) {
      return addressObj.geo_address;
    }
    if (addressObj.address) {
      return getProperGeoAddressString(addressObj.address);
    }
  }
  return "";
};
export const polylineDecode = (str, precision = 5) => {
  const coordinates = [];
  // eslint-disable-next-line one-var
  let index = 0,
    lat = 0,
    lng = 0,
    shift = 0,
    result = 0,
    byte = null,
    latitudeChange,
    longitudeChange;
  // eslint-disable-next-line no-restricted-properties
  const factor = Math.pow(10, precision);

  // Coordinates have variable length when encoded, so just keep
  // track of whether we've hit the end of the string. In each
  // loop iteration, a single coordinate is decoded.
  while (index < str.length) {
    // Reset shift, result, and byte
    byte = null;
    shift = 0;
    result = 0;

    do {
      byte = str.charCodeAt(index++) - 63;
      // eslint-disable-next-line no-bitwise
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);

    // eslint-disable-next-line no-bitwise
    latitudeChange = result & 1 ? ~(result >> 1) : result >> 1;

    // eslint-disable-next-line no-multi-assign
    shift = result = 0;

    do {
      byte = str.charCodeAt(index++) - 63;
      // eslint-disable-next-line no-bitwise
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);

    // eslint-disable-next-line no-bitwise
    longitudeChange = result & 1 ? ~(result >> 1) : result >> 1;

    lat += latitudeChange;
    lng += longitudeChange;

    coordinates.push({ lat: lat / factor, lng: lng / factor });
  }

  return coordinates;
};
