import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";

const badgeStyle = {
  color: "#000",
  minWidth: "150px",
  fontSize: "12px",
  padding: "0 4px",
  textAlign: "center",
  marginLeft: "8px",
  borderRadius: "50px",
  backgroundColor: "#fff",
  display: "block",
};

FinanceTabBadge.propTypes = {
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  badge: PropTypes.node,
  label: PropTypes.node.isRequired,
};

export default function FinanceTabBadge({ label, badge, prefix, suffix }) {
  return (
    <div style={{ paddingTop: "15px" }}>
      {_.isNil(badge) ? null : (
        <div style={badgeStyle}>
          {prefix}
          {badge}
          {suffix}
        </div>
      )}
      <div style={{ textAlign: "center" }}>{label}</div>
    </div>
  );
}
