import { OrderedSet } from "immutable";

export const PENDING = "PENDING";
export const APPROVED = "APPROVED";
export const PAYMENT_CONFIRMATION = "PAYMENT_CONFIRMATION";
export const COMPLETED = "COMPLETED";
export const REJECTED = "REJECTED";

export default OrderedSet.of(
  PENDING,
  APPROVED,
  PAYMENT_CONFIRMATION,
  COMPLETED,
);
