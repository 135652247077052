import { getDay, differenceInCalendarDays } from "date-fns";
import { RuleList } from "./Rule";
import fp from "lodash/fp";
import { List, Map, Set } from "immutable";
import { formatText } from "./FormatUtils";
import {
  FRIDAY,
  MONDAY,
  SUNDAY,
  TUESDAY,
  SATURDAY,
  THURSDAY,
  WEDNESDAY,
} from "../constants/WeekDayTypes";
import {
  TODAY,
  TOMORROW,
  EARLIER_THAN_TODAY,
} from "../constants/PromiseDateTypes";
import {
  SIZE,
  SUPPLIER,
  WEEK_DAY,
  RULE_NAME,
  WAREHOUSE,
  ADDRESS_TYPE,
  COURIER_TYPE,
  PROMISE_DATE,
  JURISDICTION_TO,
  COUNT,
  TO_POSTCODE,
} from "../constants/OrderSortingField";
import { DELIVERY, RETURNING } from "../constants/FlowTypes";
import {
  COURIER_SERVICE_WITHOUT_BAG,
  ONE_STEP_WITHOUT_BAG,
  PARCEL,
} from "./OrderOutboundSortingHelper";

export const FAILED_BIN = "Failed";
export const INCOMPLETE_BIN = "Incomplete";
export const UNSORTED_BIN = "Unsorted";
export const UNASSIGNED_BIN = "Unassigned";
export const NOTFOUND_BIN = "Not Found";

const promiseDateId = order => {
  const promiseDate = order.get("promise_date", null);

  if (promiseDate) {
    const diffDate = differenceInCalendarDays(promiseDate, new Date());

    if (diffDate < 0) return EARLIER_THAN_TODAY;

    if (diffDate === 0) return TODAY;
    else if (diffDate === 1) return TOMORROW;
  }

  return null;
};

const getWeekDay = () => {
  const day = getDay(new Date());

  switch (day) {
    case 0:
      return SUNDAY;
    case 1:
      return MONDAY;
    case 2:
      return TUESDAY;
    case 3:
      return WEDNESDAY;
    case 4:
      return THURSDAY;
    case 5:
      return FRIDAY;
    default:
      return SATURDAY;
  }
};

const getJurisdictionHierarchy = (order, key = "to_jurisdiction") => {
  const jurisdictions = [];
  const currentJurisdictionId = order.getIn([key, "id"], null);
  const currentJurisdictionName = order.getIn([key, "name"], null);
  const parentsJurisdiction = order.getIn([key, "hierarchy"], List());

  parentsJurisdiction.forEach(jurisdiction => {
    jurisdictions.push({
      id: jurisdiction.get("id"),
      name: jurisdiction.get("name"),
    });
  });

  if (currentJurisdictionId && currentJurisdictionName) {
    jurisdictions.push({
      id: currentJurisdictionId,
      name: currentJurisdictionName,
    });
  }

  return jurisdictions;
};

const getDestinationJurisdiction = order => {
  const flow = order.get("flow", DELIVERY);

  if (flow === RETURNING)
    return getJurisdictionHierarchy(order, "from_jurisdiction");

  return getJurisdictionHierarchy(order);
};

export const getDestinationCountry = order => {
  const flow = order.get("flow", DELIVERY);

  if (flow === RETURNING)
    return order.getIn(["from_jurisdiction", "country_code"], null);

  return order.getIn(["to_jurisdiction", "country_code"], null);
};

const getDestinationPostcode = order => {
  const flow = order.get("flow", DELIVERY);

  if (flow === RETURNING) return order.getIn(["from_postcode", "name"], null);

  return order.getIn(["to_postcode", "name"], null);
};

const createMatcher = order => ({
  size: order.get("size"),

  promise_date: promiseDateId(order),
  week_day: getWeekDay(),
  address_type: order.get("address_type", null),
  courier_type: order.getIn(["package", "courier_type"], null),
  to_postcode: getDestinationPostcode(order),

  supplier: {
    id: order.getIn(["supplier", "id"], null),
  },

  warehouse: {
    id: order.getIn(["warehouse", "id"], null),
    name: order.getIn(["warehouse", "name"], null),
  },

  to_jurisdiction: getDestinationJurisdiction(order),
});

const createBinName = (rule, order) => {
  if (!rule) {
    return UNSORTED_BIN;
  }

  const groupBy = rule.get("group_by");

  if (groupBy) {
    const nameChunks = [];

    if (groupBy.contains(RULE_NAME)) {
      nameChunks.push(rule.get("name"));
    }

    if (groupBy.contains(PROMISE_DATE)) {
      const promiseDateText = promiseDateId(order);

      if (promiseDateText) nameChunks.push(formatText(promiseDateText));
    }

    if (groupBy.contains(WAREHOUSE)) {
      const warehouse = order.getIn(["warehouse", "name"]);

      if (warehouse) {
        nameChunks.push(warehouse);
      }
    }

    if (groupBy.contains(TO_POSTCODE)) {
      const warehouse = order.getIn(["to_postcode", "name"]);

      if (warehouse) {
        nameChunks.push(warehouse);
      }
    }

    if (groupBy.contains(SUPPLIER)) {
      const supplier = order.getIn(["supplier", "name"]);

      if (supplier) {
        nameChunks.push(supplier);
      }
    }

    if (groupBy.contains(JURISDICTION_TO)) {
      const jurisdiction = order.getIn(["to_jurisdiction", "name"]);

      if (jurisdiction) {
        nameChunks.push(jurisdiction);
      }
    }

    if (groupBy.contains(COURIER_TYPE)) {
      const service = order.getIn(["package", "courier_type_name"]);

      if (service) {
        nameChunks.push(service);
      }
    }

    if (groupBy.contains(SIZE)) {
      const size = order.get("size");

      if (size) {
        nameChunks.push(formatText(size));
      }
    }

    if (groupBy.contains(WEEK_DAY)) {
      nameChunks.push(formatText(getWeekDay()));
    }

    if (groupBy.contains(ADDRESS_TYPE)) {
      const addressType = order.get("address_type");

      if (addressType) {
        nameChunks.push(formatText(addressType));
      }
    }

    if (nameChunks.length > 0) {
      return nameChunks.join(" - ");
    }
  }

  return rule.get("name");
};

// export const createOrderSortingBinCreator = rules => {
//   const ruleList = new RuleList(rules.toJS());
//
//   return order => {
//     const matcher = createMatcher(order);
//     const code = ruleList.process(matcher);
//     return createBinName(rules.get(code), order);
//   };
// };

export const createOrderSortingBinCreator = rules => {
  const ruleList = new RuleList(rules.toJS());

  return order => {
    const matcher = createMatcher(order);
    const code = ruleList.process(matcher);
    const name = createBinName(rules.get(code), order);
    return { code: code || null, bin: name };
  };
};

export const ableToCreateRegistry = courierTypes =>
  !courierTypes.includes(PARCEL) &&
  !courierTypes.includes(COURIER_SERVICE_WITHOUT_BAG) &&
  !courierTypes.includes(ONE_STEP_WITHOUT_BAG);

export const isParcelServiceType = serviceType =>
  serviceType === PARCEL ||
  serviceType === COURIER_SERVICE_WITHOUT_BAG ||
  serviceType === ONE_STEP_WITHOUT_BAG;

// export const createOrderSortingBinCreator = rules => {
//   const ruleList = new RuleList(rules.toJS());
//
//   return (order, responseAsObject = false) => {
//     const matcher = createMatcher(order);
//     const code = ruleList.process(matcher);
//     const selectedRule = rules.get(code);
//     if (responseAsObject) {
//       const bin = createBinName(selectedRule, order);
//       if (
//         selectedRule &&
//         !fp.isEmpty(selectedRule.get("future_status", null))
//       ) {
//         return { bin, future_status: selectedRule.get("future_status") };
//       }
//       return { bin };
//     }
//     return createBinName(selectedRule, order);
//   };
// };

export const isExpiredOrderRecord = x =>
  !x.get("hash") ||
  !x.get("hash_time") ||
  Date.now() - 10 * 60 * 1000 > x.get("hash_time");

export const getBinFutureStatus = (bin, rules) => {
  if (rules) {
    const findRuleByName = fp.flow(
      fp.find(item => fp.isEqual(item.name, bin)),
      fp.pick(["group", "count"]),
    );

    return findRuleByName(rules.toJS());
  }

  return null;
};

export const getBinByJurisdiction = ({
  initialBins,
  initialBin,
  order,
  rule,
}) => {
  const postcodePath = ["info", "to_postcode", "name"];
  const toJurisdictionPath = ["info", "to_jurisdiction", "name"];
  const toJurisdictionIdPath = ["info", "to_jurisdiction", "id"];
  const toJurisdictionHierarchyPath = ["info", "to_jurisdiction", "hierarchy"];
  const serviceTypePath = ["info", "package", "courier_type"];
  const serviceTypeNamePath = ["info", "package", "courier_type_name"];
  const serviceType = order.getIn(serviceTypePath, null);

  if (!rule || !serviceType) return null;

  const serviceTypeName = order.getIn(serviceTypeNamePath, null)
    ? order.getIn(serviceTypeNamePath)
    : serviceType;
  const bins = initialBins.asMutable();
  const orderNumber = order.get("number");
  const groupBy = rule.get("group_by");
  const divisionCount = rule.get("count", 0);

  if (!groupBy.contains(COUNT)) return null;

  const paths = [];
  const postcode = order.getIn(postcodePath, null);
  const toJurisdiction = order.getIn(toJurisdictionPath, null);
  const toJurisdictionId = order.getIn(toJurisdictionIdPath, null);
  const toJurisdictionHierarchy = order.getIn(
    toJurisdictionHierarchyPath,
    List(),
  );

  if (!postcode && !toJurisdiction && toJurisdictionHierarchy.size === 0)
    return null;

  // Group by Postcode
  if (postcode) {
    const postcodeBin = [postcode, serviceTypeName].join(" - ");
    paths.push(postcodeBin);
    if (!bins.has(postcodeBin)) {
      bins.set(
        postcodeBin,
        Map({
          label: postcodeBin,
          mainLabel: initialBin,
          code: order.get("code"),
          postcode: rule.get("postcode", null),
          bin_postcode: postcode,
          selected: 0,
          country: false,
          orders: Set(),
          level: 1,
          courierTypes: Set(),
        }),
      );
    }

    bins.updateIn([postcodeBin, "orders"], x => x.add(orderNumber));
  }

  // Group by To Jurisdiction
  let hierarchyLength = 2;
  if (toJurisdiction) {
    const toJurisdictionBin = [toJurisdiction, serviceTypeName].join(" - ");
    paths.push(toJurisdictionBin);
    if (!bins.has(toJurisdictionBin)) {
      bins.set(
        toJurisdictionBin,
        Map({
          label: toJurisdictionBin,
          mainLabel: initialBin,
          code: order.get("code"),
          postcode: order.get("postcode", null),
          bin_postcode: postcode,
          jurisdiction: toJurisdictionId,
          selected: 0,
          orders: Set(),
          country: toJurisdictionHierarchy.size === 0,
          level: hierarchyLength,
          courierTypes: Set(),
        }),
      );
    }
    bins.updateIn([toJurisdictionBin, "orders"], x => x.add(orderNumber));
    hierarchyLength++;
  }

  // Group by Hierarchy of Jurisdiction
  if (toJurisdictionHierarchy) {
    toJurisdictionHierarchy.reverse().forEach((jurisdiction, index) => {
      const name = jurisdiction.get("name");
      const jurisdictionId = jurisdiction.get("id");
      const toJurisdictionNameBin = [name, serviceTypeName].join(" - ");
      paths.push(toJurisdictionNameBin);
      if (!bins.has(toJurisdictionNameBin)) {
        bins.set(
          toJurisdictionNameBin,
          Map({
            label: toJurisdictionNameBin,
            mainLabel: initialBin,
            code: order.get("code"),
            postcode: order.get("postcode", null),
            bin_postcode: postcode,
            jurisdiction: jurisdictionId,
            selected: 0,
            orders: Set(),
            country: index === toJurisdictionHierarchy.size - 1,
            level: hierarchyLength,
            courierTypes: Set(),
          }),
        );
      }
      bins.updateIn([toJurisdictionNameBin, "orders"], x => x.add(orderNumber));
      hierarchyLength++;
    });
  }

  let removedOrders = Set();
  paths.forEach(path => {
    let orders = bins.getIn([path, "orders"]);
    if (removedOrders.size > 0) {
      orders = orders.subtract(removedOrders);
    }

    if (orders.size >= divisionCount) {
      removedOrders = orders;
      bins.setIn([path, "post_packet"], true);
    }
    bins.setIn([path, "orders"], orders);
  });

  return bins.asImmutable();
};

export function replaceMiddleString(string = "", n) {
  const count = (string || "").length > 10 ? n : 2;
  const rest = (string || "").length - count;

  const start = Math.floor(rest / 2);
  const end = Math.floor(rest / 2);
  const length = (string || "").length - start - end;

  return (string || "").replace(
    (string || "").substring(start, start + length),
    "*".repeat(length),
  );
}

export function validateUserWarehouse(
  currentWarehouseId,
  availableWarehouseIds,
) {
  if (!currentWarehouseId) return false;

  return !(
    availableWarehouseIds && !availableWarehouseIds.includes(currentWarehouseId)
  );
}
