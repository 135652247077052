import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { Paper, Button, Fab } from "@material-ui/core";
import { connect } from "react-redux";
import { Placemark } from "react-yandex-maps";
import { MyLocation as MapsMyLocation } from "@material-ui/icons";
import { PanTo } from "react-google-map-components";
import FormGeoAutoComplete from "../form/FormGeoAutoComplete";
import FormYandexGeoAutoComplete from "../form/FormYandexGeoAutoComplete";
import BrandMarker from "../maps/BrandMarker";
import Geolocation from "../maps/Geolocation";
import GoogleMapWrapper from "../maps/GoogleMapWrapper";
import YandexMapWrapper from "../maps/YandexMapWrapper";
import FlexBox, { FLEX_NOSHRINK } from "../ui-core/FlexBox";
import ModalPaper from "../ui-core/ModalPaper";
import { renderIf } from "../../helpers/HOCUtils";
import { isValidCoordinates } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { primary1 } from "../../../shared/theme/main-theme";
import { responsive } from "../../../shared/theme/jss-responsive";
import { getMapProvider } from "../../../shared/reducers/AppReducer";
import {
  GOOGLE_MAP_PROVIDER,
  YANDEX_MAP_PROVIDER,
} from "../../../shared/constants/MapsControllerConstants";
import LeafletBrandMarker from "../maps/osm/BrandMaker";
import { PanTo as LeafletPanTo } from "react-leflet-map-components";
import LeafletMapWrapper from "../maps-leaflet/LeafletMapWrapper";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      mapProvider: getMapProvider(state),
      getLocalisationMessage,
    };
  }),
  renderIf("open"),
  useSheet({
    body: { padding: 0, position: "relative" },
    map: { height: "448px", zIndex: 0 },
    yandexMap: { flex: "1 1 0%", zIndex: 1, position: "relative" },
    modal: {
      [responsive("$xs or $sm or $md")]: { minWidth: "100%" },
    },

    actions: {
      zIndex: 10,
      top: "12px",
      left: "12px",
      right: "12px",
      padding: "0 8px",
      marginLeft: "45px",
      position: "absolute",
    },
    geoField: {
      // [responsive("$xs")]: { display: "none" },
    },
    geoFieldWrapper: {
      [responsive("$xs")]: { minWidth: "100%" },
    },
    point: {
      position: "absolute",
      padding: 10,
      right: 0,
      bottom: 0,
      zIndex: 9,
    },
  }),
  reduxForm({ form: "FormMapPinDialog", enableReinitialize: true }),
  formValues({ location: "location" }),
);

FormMapPinDialog.propTypes = {
  classes: PropTypes.object,
  change: PropTypes.func,
  children: PropTypes.node,
  handleSubmit: PropTypes.func,

  location: PropTypes.object,
  mapCenter: PropTypes.object,
  mapProvider: PropTypes.string,

  countryCode: PropTypes.string.isRequired,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func,

  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

FormMapPinDialog.defaultProps = {
  onRequestClose: null,
};

function FormMapPinDialog(props) {
  const { classes, getLocalisationMessage } = props;

  const isYandexMap = props.mapProvider === YANDEX_MAP_PROVIDER;
  const isGoogleMapProvider = props.mapProvider === GOOGLE_MAP_PROVIDER;

  return (
    <ModalPaper
      open={props.open}
      onRequestClose={props.onRequestClose}
      paperClassName={classes.modal}
    >
      <Paper className={classes.actions}>
        <FlexBox wrap={true}>
          <FlexBox flex={FLEX_NOSHRINK} className={classes.geoFieldWrapper}>
            {isYandexMap ? (
              <FormYandexGeoAutoComplete
                fullWidth={true}
                name="location"
                className={classes.geoField}
                countryCode={props.countryCode}
                hintText={getLocalisationMessage(
                  "type_to_search_5_letters",
                  "Type to Search (5 letters minimum)",
                )}
              />
            ) : (
              <FormGeoAutoComplete
                name="location"
                fullWidth={true}
                underlineShow={false}
                className={classes.geoField}
                countryCode={props.countryCode}
                hintText={getLocalisationMessage(
                  "type_to_search_3_letters",
                  "Type to Search (3 letters minimum)",
                )}
              />
            )}
          </FlexBox>

          <FlexBox align="center" style={{ marginLeft: "auto" }}>
            {props.onRequestClose && (
              <Button key={0} onClick={props.onRequestClose}>
                {getLocalisationMessage("dismiss", "Dismiss")}
              </Button>
            )}
            <Button key={0} onClick={props.handleSubmit}>
              {getLocalisationMessage("submit", "Submit")}
            </Button>
          </FlexBox>
        </FlexBox>
      </Paper>
      <div className={classes.closeButton} />
      {isYandexMap && (
        <YandexMapWrapper
          className={classes.yandexMap}
          height={480}
          mapCenter={props.location}
          onClick={event => {
            const x = event.get("coords");
            props.change("location", {
              lat: x[0],
              lng: x[1],
            });
          }}
        >
          {isValidCoordinates(props.location) && (
            <Placemark geometry={[props.location.lat, props.location.lng]} />
          )}
        </YandexMapWrapper>
      )}{" "}
      {isGoogleMapProvider && (
        <GoogleMapWrapper
          className={classes.map}
          mapCenter={props.mapCenter}
          onClick={x =>
            props.change("location", {
              lat: x.latLng.lat(),
              lng: x.latLng.lng(),
            })
          }
        >
          {isValidCoordinates(props.location) && (
            <div>
              <PanTo latLng={props.location} />
              <BrandMarker position={props.location} />
            </div>
          )}
        </GoogleMapWrapper>
      )}
      {!isYandexMap && !isGoogleMapProvider && (
        <LeafletMapWrapper
          className={classes.map}
          mapCenter={props.mapCenter}
          onClick={x => {
            props.change("location", {
              lat: x.latlng.lat,
              lng: x.latlng.lng,
            });
          }}
        >
          {isValidCoordinates(props.location) && (
            <div>
              <LeafletPanTo latLng={props.location} />
              <LeafletBrandMarker position={props.location} />
            </div>
          )}
        </LeafletMapWrapper>
      )}
      <div className={classes.point}>
        <Geolocation
          onSuccess={({ coords: { latitude, longitude } }) =>
            props.change("location", { lat: latitude, lng: longitude })
          }
          render={({ getCurrentPosition }) => (
            <div>
              <Fab
                color="primary"
                backgroundColor="#fff"
                onClick={getCurrentPosition}
              >
                <MapsMyLocation style={{ fill: primary1 }} />
              </Fab>
            </div>
          )}
        />
      </div>
      {props.children}
    </ModalPaper>
  );
}

export default enhancer(FormMapPinDialog);
