import fp from "lodash/fp";
import DataListFilter, { getListFilterSchema } from "./DataListFilter";
import {
  types,
  mapSchema,
  queryTypes,
} from "../../shared/helpers/ObjectMapper";

export const toCODTransferFilter = fp.compose(
  DataListFilter.create,
  mapSchema({
    ...getListFilterSchema(),
    from_date: queryTypes.date,
    use_solr: types.boolean,
    to_date: queryTypes.date,
    status: queryTypes.stringSeq,
  }),
);
