import React from "react";
import { isToday, isTomorrow, isYesterday } from "date-fns";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Avatar } from "@material-ui/core";
import { connect } from "react-redux";
import {
  Timelapse as ImageTimelapse,
  AddCircleOutline as ContentAddCircleOutline,
} from "@material-ui/icons";
import { withTheme } from "@material-ui/core/styles";
import TextWithLink from "../router/TextWithLink";
import UserAvatar from "../avatars/UserAvatar";
import Text, { ALIGN_RIGHT } from "../ui-core/Text";
import FlexBox from "../ui-core/FlexBox";
import TimeSlotAvatar from "../time-slots-core/TimeSlotAvatar";
import { safeParseDate } from "../../helpers/DateUtils";
import {
  formatDate,
  formatTime,
  DATE_SHORT_FORMAT,
  formatDateTimeToUrl,
  formatDateDistanceToNow,
} from "../../helpers/FormatUtils";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import { formatTimeSlot } from "../../helpers/TimeSlotHelper";
import { getUser } from "../../reducers/ProfileReducer";
import { isReverseLogisticEnabled } from "../../reducers/MarketplaceReducer";
import { getIsRTL, getMessage } from "../../reducers/LocalizationReducer";
import { REVERSE } from "../../constants/LogisticTypes";
import { getStatusLocalisation } from "../../reducers/localisation/OrderStatusLocalisationReducer";
import { ROLE_ADMIN_VIEWER } from "../../../shared/constants/Authorities";
import PickupTimeSlot from "./assets/pickup.png";
import DropoffTimeSlot from "./assets/dropoff.png";
import { hasRole } from "../../helpers/RoleUtils";

const humanizeDate = (date, getLocalisationMessage) => {
  const value = safeParseDate(date);

  if (isTomorrow(value)) {
    return getLocalisationMessage("tomorrow", "Tomorrow");
  }

  if (isToday(value)) {
    return getLocalisationMessage("today", "Today");
  }

  if (isYesterday(value)) {
    return getLocalisationMessage("yesterday", "Yesterday");
  }

  return formatDate(value, DATE_SHORT_FORMAT);
};

const humanizeDateTime = (date, getLocalisationMessage) =>
  `${humanizeDate(date, getLocalisationMessage)} ${formatTime(date)}`;

const enhancer = compose(
  connect((state) => {
    const userRoles = getUser(state).get("roles") || [];
    return {
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      statusMessages: getStatusLocalisation(state),
      isAdminViewer: hasRole(userRoles, ROLE_ADMIN_VIEWER),
      isRTL: getIsRTL(state),
      reverseLogisticEnabled: isReverseLogisticEnabled(state),
    };
  }),
  withTheme,
  useSheet({
    root: {
      flex: "1 1 0%",
      height: "150px",
      padding: "12px",
      display: "flex",
      flexDirection: "column",

      color: (props) => props.theme.palette.alternateTextColor,

      "& a": {
        color: (props) => props.theme.palette.alternateTextColor,
      },
    },

    panel: {
      display: "flex",
      flexDirection: "row",
      "& > div": {
        flex: "1 1 0%",
        fontFamily: "Blogger Sans",
      },
    },

    rootIdLine: {
      marginRight: 30,
    },

    idLine: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      position: "relative",
      maxWidth: "225px",
      "&:hover": {
        overflow: "visible",
        "& span": {
          backgroundColor: (props) => props.theme.palette.primary1Color,
          boxShadow: (props) =>
            `0 0 0px 2px ${props.theme.palette.primary1Color}`,
          padding: "5px 0",
        },
      },
    },

    createdDate: {
      width: "32px",
      height: "32px",
      margin: "4px",
      fill: "#0005",
    },

    informerTextBlock: {
      marginTop: 5,
      lineHeight: 1.2,
      marginLeft: (props) => (!props.isRTL ? "8px" : "0"),
      marginRight: (props) => (props.isRTL ? "8px" : "0"),
      flexDirection: "column",
      justifyContent: "center",
      textTransform: "uppercase",
    },

    informerSecondaryText: { fontSize: "12px" },

    shipment: { textAlign: "right" },
    customer: { display: "flex", marginTop: "20px", flexDirection: "row" },
    customerTextBlock: {
      marginLeft: (props) => (!props.isRTL ? "12px" : "0"),
      marginRight: (props) => (props.isRTL ? "12px" : "0"),
      lineHeight: "15px",
      paddingTop: "4px",
      textTransform: "uppercase",
    },
    customerTitle: {
      color: (props) => props.theme.palette.alternateTextColor,
      fontSize: "10px",
    },

    courier: {
      display: "flex",
      marginTop: "20px",
      textAlign: (props) => (props.isRTL ? "left" : "right"),
      flexDirection: "row-reverse",
    },

    courierTextBlock: {
      paddingTop: "4px",
      lineHeight: "15px",
      marginLeft: (props) => (!props.isRTL ? "0" : "12px"),
      marginRight: (props) => (props.isRTL ? "0" : "12px"),
      textTransform: "uppercase",
    },
    courierTitle: {
      color: (props) => props.theme.palette.alternateTextColor,
      fontSize: "10px",
    },
    courierLastSync: {
      fontSize: "12px",
      color: (props) => props.theme.palette.alternateTextColor,
    },
    toCourier: { textAlign: "center", marginTop: "20px", lineHeight: "18px" },
    toCourierTime: { fontSize: "12px" },
  }),
);

OrderDetailsDialogHeader.propTypes = {
  classes: PropTypes.object,
  getDriverUrl: PropTypes.func,
  getSupplierUrl: PropTypes.func,
  getCustomerUrl: PropTypes.func,
  isAdminViewer: PropTypes.bool,
  order: PropTypes.instanceOf(Map).isRequired,
  lastSyncDate: PropTypes.string,
  getLocalisationMessage: PropTypes.func.isRequired,
  theme: PropTypes.object,
  getReverseUrl: PropTypes.func,
  reverseLogisticEnabled: PropTypes.bool,
};

OrderDetailsDialogHeader.defaultProps = {
  getDriverUrl: fp.noop,
  getSupplierUrl: fp.noop,
  getCustomerUrl: fp.noop,
};

function OrderDetailsDialogHeader(props) {
  const { classes, order, getLocalisationMessage } = props;
  const NA = getLocalisationMessage("na", "N/A");

  return (
    <div className={classes.root}>
      <div className={classes.panel}>
        <div className={classes.rootIdLine}>
          <div className={classes.idLine}>
            <span>
              {getLocalisationMessage("order", "Order")}:{" "}
              {order.get("order_number") || NA}
            </span>
          </div>

          {Boolean(
            order.get("logistic_type") === REVERSE &&
              order.has("head_order_id") &&
              props.reverseLogisticEnabled,
          ) && (
            <div className={classes.idLine}>
              <span>
                {getLocalisationMessage("reverse_order", "Reverse Order")}:{" "}
                <TextWithLink
                  to={props.getReverseUrl(order.get("head_order_id"))}
                >
                  {order.get("reverse_order_number")}
                </TextWithLink>
              </span>
            </div>
          )}

          {Boolean(
            order.get("logistic_type") === REVERSE &&
              order.has("back_order_id") &&
              props.reverseLogisticEnabled,
          ) && (
            <div className={classes.idLine}>
              <span>
                {getLocalisationMessage("reverse_order", "Reverse Order")}:{" "}
                <TextWithLink
                  to={props.getReverseUrl(order.get("back_order_id"))}
                >
                  {order.get("reverse_order_number")}
                </TextWithLink>
              </span>
            </div>
          )}

          {order.get("reference_id") && (
            <div className={classes.idLine}>
              <span>
                {getLocalisationMessage("ref", "Ref#")}:{" "}
                {order.get("reference_id") || NA}
              </span>
            </div>
          )}
        </div>

        <FlexBox>
          <Avatar
            size={40}
            style={{
              backgroundColor: "#fff",
            }}
          >
            <ContentAddCircleOutline className={classes.createdDate} />
          </Avatar>

          <div className={classes.informerTextBlock}>
            <div>{getLocalisationMessage("created", "Created")}:</div>
            <div className={classes.informerSecondaryText}>
              {humanizeDateTime(
                order.get("created_date"),
                getLocalisationMessage,
              )}
            </div>
          </div>
        </FlexBox>

        <FlexBox>
          {order.get("pickup_timeslot") ? (
            <TimeSlotAvatar name={order.getIn(["pickup_timeslot", "name"])} />
          ) : (
            <Avatar
              src={PickupTimeSlot}
              style={{
                backgroundColor: "transparent",
              }}
            />
          )}

          <div className={classes.informerTextBlock}>
            <div>
              {getLocalisationMessage("pick_up", "Pick Up")}:{" "}
              {order.get("pickup_timeslot") &&
                humanizeDate(
                  order.get("estimated_pickup_time"),
                  getLocalisationMessage,
                )}
            </div>

            <div className={classes.informerSecondaryText}>
              {order.get("pickup_timeslot")
                ? `${order.getIn(["pickup_timeslot", "name"])} ${formatTimeSlot(
                    order.get("pickup_timeslot"),
                  )}`
                : order.get("pickup_time")
                ? humanizeDateTime(
                    order.get("pickup_time"),
                    getLocalisationMessage,
                  )
                : getLocalisationMessage("asap", "ASAP")}
            </div>
          </div>
        </FlexBox>

        <FlexBox style={{ display: "none" }}>
          {order.get("delivery_timeslot") ? (
            <TimeSlotAvatar name={order.getIn(["delivery_timeslot", "name"])} />
          ) : (
            <Avatar
              src={DropoffTimeSlot}
              style={{
                backgroundColor: "transparent",
              }}
            />
          )}
          <div className={classes.informerTextBlock}>
            <div>
              {getLocalisationMessage("delivery", "Delivery")}:{" "}
              {order.get("delivery_timeslot") &&
                humanizeDate(
                  order.get("estimated_delivery_time"),
                  getLocalisationMessage,
                )}
            </div>

            <div className={classes.informerSecondaryText}>
              {order.get("delivery_timeslot")
                ? `${order.getIn([
                    "delivery_timeslot",
                    "name",
                  ])} ${formatTimeSlot(order.get("delivery_timeslot"))}`
                : order.get("deadline_time")
                ? humanizeDateTime(
                    order.get("deadline_time"),
                    getLocalisationMessage,
                  )
                : getLocalisationMessage("asap", "ASAP")}
            </div>
          </div>
        </FlexBox>

        <FlexBox
          style={{
            visibility: order.get("merchant_promise_date")
              ? "visible"
              : "hidden",
          }}
        >
          <Avatar
            size={40}
            style={{
              backgroundColor: "#fff",
            }}
          >
            <ImageTimelapse className={classes.createdDate} />
          </Avatar>

          <div className={classes.informerTextBlock}>
            <div>
              {getLocalisationMessage("promise_date", "Promised Date") ||
                getLocalisationMessage("shipment_id", "SHIPMENT ID:")}
              :
            </div>
            <div className={classes.informerSecondaryText}>
              {humanizeDateTime(
                order.get("merchant_promise_date"),
                getLocalisationMessage,
              )}
            </div>
          </div>
        </FlexBox>

        <div>
          {order.get("shipment_number") && (
            <Text type={ALIGN_RIGHT} element="div">
              {getLocalisationMessage("shipment_id", "SHIPMENT ID:")}{" "}
              {order.get("shipment_number")}
            </Text>
          )}
        </div>
      </div>

      <div className={classes.panel}>
        <div>
          <div className={classes.customer}>
            <UserAvatar size={40} name={order.getIn(["customer", "name"])} />

            <div className={classes.customerTextBlock}>
              <span className={classes.customerTitle}>
                {getLocalisationMessage("customer", "Customer")}
              </span>
              <br />
              {!props.isAdminViewer ? (
                <TextWithLink
                  target="_blank"
                  to={props.getCustomerUrl(order.getIn(["customer", "id"]))}
                >
                  {order.getIn(["customer", "name"])}
                </TextWithLink>
              ) : (
                order.getIn(["customer", "name"])
              )}
            </div>
          </div>
        </div>

        <div>
          <div className={classes.toCourier}>
            <div>
              {formatOrderStatusCodeForLocalisation(
                order.get("status"),
                getLocalisationMessage,
              )}
            </div>

            <div className={classes.toCourierTime}>
              {order.get("last_status_date")
                ? formatDateDistanceToNow(
                    order.get("last_status_date"),
                    null,
                    getLocalisationMessage,
                  )
                : getLocalisationMessage("na", "N/A")}
            </div>
          </div>
        </div>

        <div>
          {Boolean(order.get("driver") || order.get("supplier")) && (
            <div className={classes.courier}>
              <UserAvatar
                size={40}
                src={order.getIn(["driver", "description"])}
                name={
                  order.getIn(["driver", "name"]) ||
                  order.getIn(["supplier", "name"])
                }
              />
              <div className={classes.courierTextBlock}>
                <div className={classes.courierTitle}>
                  {getLocalisationMessage("courier_company")}
                </div>

                <div>
                  {Boolean(order.get("supplier")) && (
                    <span>
                      {!props.isAdminViewer ? (
                        <TextWithLink
                          target="_blank"
                          to={props.getSupplierUrl(
                            order.getIn(["supplier", "id"]),
                          )}
                        >
                          {order.getIn(["supplier", "name"])}
                        </TextWithLink>
                      ) : (
                        order.getIn(["supplier", "name"])
                      )}
                    </span>
                  )}

                  {Boolean(order.get("driver") && order.get("supplier")) &&
                    " / "}

                  {Boolean(order.get("driver")) && (
                    <span>
                      {!props.isAdminViewer ? (
                        <TextWithLink
                          target="_blank"
                          to={props.getDriverUrl(order.getIn(["driver", "id"]))}
                        >
                          {order.getIn(["driver", "name"])}
                        </TextWithLink>
                      ) : (
                        order.getIn(["driver", "name"])
                      )}
                    </span>
                  )}
                </div>

                {props.lastSyncDate && (
                  <div className={classes.courierLastSync}>
                    {getLocalisationMessage(
                      "last_sync_date",
                      "Last Sync Date:",
                    )}{" "}
                    <span>{formatDateTimeToUrl(props.lastSyncDate)}</span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default enhancer(OrderDetailsDialogHeader);
