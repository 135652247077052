import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import FormTextField from "../form/FormTextField";
import FormNeighborhoodV2AutoComplete from "../form/FormNeighborhoodV2AutoComplete";

const enhancer = compose(
  useSheet({
    root: {
      width: "100%",
      paddingRight: 5,
    },
  }),
);

OrderNeighborhoodField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  classes: PropTypes.object,
  readOnly: PropTypes.bool,
  onClick: PropTypes.func,
  location: PropTypes.instanceOf(Map).isRequired,
};

function OrderNeighborhoodField(props) {
  const { classes, location } = props;

  const autocomplete = Boolean(
    location.getIn(["country", "id"]) && location.getIn(["neighborhood", "id"]),
  );

  return (
    <div className={classes.root}>
      {autocomplete ? (
        <FormNeighborhoodV2AutoComplete
          includeUnknownNeighborhood={false}
          fullWidth={true}
          name={props.name}
          readOnly={props.readOnly}
          countryId={location.getIn(["country", "id"])}
          cityId={location.get(["city", "id"])}
          hintText={props.label}
          onClick={() => props.onClick && props.onClick(props.readOnly)}
          label={props.label}
        />
      ) : (
        <FormTextField fullWidth={true} name={props.name} label={props.label} />
      )}
    </div>
  );
}

export default enhancer(OrderNeighborhoodField);
