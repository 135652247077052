import React from "react";
import fp from "lodash/fp";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { isEqualData } from "../../helpers/DataUtils";
import { mergeSideEffectStreams } from "../../helpers/StreamUtils";

const enhancer = compose(
  mapPropsStream(propsStream => {
    const nodeRef = createEventHandler();

    const sideEffectStream = mergeSideEffectStreams(
      propsStream
        .map(fp.pick(["src", "play"]))
        .distinctUntilChanged(isEqualData)
        .combineLatest(nodeRef.stream)
        .switchMap(
          ([props, node]) =>
            !node
              ? Promise.resolve(null)
              : Promise.resolve(props.play ? node.play() : node.pause()).catch(
                  fp.noop,
                ),
        ),
    );

    return propsStream
      .merge(sideEffectStream)
      .map(fp.set("nodeRef", nodeRef.handler));
  }),
);

Audio.propTypes = {
  nodeRef: PropTypes.func,

  play: PropTypes.bool.isRequired,
  src: PropTypes.string.isRequired,
};

function Audio(props) {
  return !props.play ? null : (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <audio src={props.src} ref={props.nodeRef} />
  );
}

export default enhancer(Audio);
