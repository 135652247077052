import React from "react";
import fp from "lodash/fp";
import PropTypes from "prop-types";
import { Avatar } from "@material-ui/core";
import night from "./assets/night.png";
import evening from "./assets/evening.png";
import morning from "./assets/morning.png";
import afternoon from "./assets/afternoon.png";

TimeSlotAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  style: PropTypes.object,
};

const trimToLower = fp.flow(fp.trim, fp.toLower);
const isMorning = fp.flow(trimToLower, fp.contains("morning"));
const isEvening = fp.flow(trimToLower, fp.contains("evening"));
const isNight = fp.flow(trimToLower, fp.contains("night"));

const getIcon = name => {
  if (isMorning(name)) {
    return morning;
  }

  if (isEvening(name)) {
    return evening;
  }

  if (isNight(name)) {
    return night;
  }

  return afternoon;
};

export default function TimeSlotAvatar({ name, style, ...props }) {
  return (
    <Avatar
      {...props}
      src={getIcon(name)}
      style={{
        padding: "6px",
        backgroundColor: "#white",
        ...style,
      }}
    />
  );
}
