import React from "react";
import { Map, Collection } from "immutable";
import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FinanceTabBadge from "./FinanceTabBadge";
import NavTabs from "../ui-core/NavTabs";
import PriceWrapper from "../ui-core/PriceWrapper";
import { formatCODStatusCode } from "../../helpers/OrderHelper";
import { getMessage } from "../../reducers/LocalizationReducer";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);
    return {
      getLocalisationMessage,
    };
  }),
);
FinanceOrderTableTabs.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.object.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  formatCODStatusCode: PropTypes.func,
  totalCounts: PropTypes.instanceOf(Map),
  statusCodes: PropTypes.instanceOf(Collection).isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

FinanceOrderTableTabs.defaultProps = { formatCODStatusCode };

const cleanupQuery = fp.omit(["page"]);

const createCalculator = counts =>
  counts
    ? fp.flow(
        fp.trim,
        fp.split(","),
        fp.map(fp.flow(key => counts.getIn([key, "total"]), fp.toFinite)),
        fp.sum,
      )
    : fp.noop;

const getCurrencyCode = counts => {
  const first = counts && counts.first();

  return first ? first.getIn(["currency", "code"]) : null;
};

function FinanceOrderTableTabs(props) {
  const { location, getLocalisationMessage } = props;

  const query = cleanupQuery(location.query);
  const value = props.statusCodes.has(query.cod_status)
    ? query.cod_status
    : props.statusCodes.keySeq().first();
  const calculate = createCalculator(props.totalCounts);
  const currencyCode = getCurrencyCode(props.totalCounts);

  return (
    <NavTabs
      width={540}
      tabItemContainerStyle={{ height: "60px" }}
      value={value}
      tabs={props.statusCodes
        .map((v, key) => {
          const total = calculate(key);
          return {
            value: key,
            to: updateQuery(location, { ...query, cod_status: key }),
            label: (
              <FinanceTabBadge
                label={getLocalisationMessage(
                  props.formatCODStatusCode(key).toLocaleLowerCase(),
                  props.formatCODStatusCode(key),
                )}
                badge={
                  total ? (
                    <PriceWrapper price={total} code={currencyCode} />
                  ) : (
                    <PriceWrapper price={0} code={currencyCode} />
                  )
                }
              />
            ),
          };
        })
        .toArray()}
    />
  );
}

export default enhancer(FinanceOrderTableTabs);
