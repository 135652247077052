import { Observable } from "rxjs";
import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  withState,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { getFormValues } from "redux-form";
import { Paper, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { Place } from "@material-ui/icons";
import FormTextField from "./FormTextField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import FormMapPinDialog from "../deprecated/FormMapPinDialog";
import { isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import { geocode } from "../../helpers/GoogleMapsHelper";
import { getMessage } from "../../reducers/LocalizationReducer";
import { log } from "../../../shared/helpers/logger";

const getCountryFromGeocodeResult = fp.flow(
  fp.get("address_components"),
  fp.find(fp.flow(fp.get("types"), fp.includes("country"))),
  fp.get("short_name"),
);
const getLatLng = fp.pick(["lat", "lng"]);
const selector = getFormValues("FormMapPinDialog");
const enhancer = compose(
  useSheet({
    iconButton: {
      marginTop: 20,
    },
    manualFieldsBox: {
      background: "white",
      position: "absolute",
      left: 20,
      bottom: 10,
      paddingLeft: 15,
      paddingRight: 15,
      paddingBottom: 10,
    },
  }),
  connect(state => ({
    values: selector(state, "location"),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  withState("state", "setState", {
    showPickupMapDialog: false,
    showDropoffMapDialog: false,
    isLoading: false,
  }),
  mapPropsStream(propsStream => {
    const { handler: blurHandler, stream: blurStream } = createEventHandler();

    const manualChangeStream = blurStream
      .distinctUntilChanged(isEqualData)
      .withLatestFrom(
        propsStream
          .map(
            fp.flow(
              fp.pick([
                "values.location.lat",
                "values.location.lng",
                "setState",
              ]),
              fp.update("values.location.lat", Number),
              fp.update("values.location.lng", Number),
            ),
          )
          .filter(
            fp.flow(
              fp.over([
                fp.flow(fp.get("values.location.lat"), fp.isFinite),
                fp.flow(fp.get("values.location.lng"), fp.isFinite),
              ]),
              fp.every(Boolean),
            ),
          )
          .distinctUntilKeyChanged("values", isEqualData),
      )
      .distinctUntilChanged(isEqualDataIn(["1", "values", "location"]))
      .do(([, { setState }]) => setState(fp.set("isLoading", true)))
      .switchMap(
        ([
          ,
          {
            values: { location },
            setState,
          },
        ]) => {
          log("switchMap == ", location);
          return Observable.defer(() => geocode({ location }))
            .catch(() => Observable.of(null))
            .map(
              fp.flow(fp.first, result =>
                fp.isEmpty(result)
                  ? null
                  : {
                      location: {
                        lat: result.geometry.location.lat,
                        lng: result.geometry.location.lng,
                        country: getCountryFromGeocodeResult(result),
                        address: result.formatted_address,
                      },
                    },
              ),
            )
            .do(() => {
              setState(fp.set("isLoading", false));
            });
        },
      )
      .filter(Boolean)
      .startWith({});

    return propsStream
      .combineLatest(manualChangeStream, (props, rest) => ({
        ...props,
        ...rest,
        blurHandler,
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

ManualSetOrderLocationDialog.propTypes = {
  getLocalisationMessage: PropTypes.func,

  state: PropTypes.object,
  setState: PropTypes.func,

  classes: PropTypes.object,

  countryCode: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,

  location: PropTypes.object,

  change: PropTypes.func,
  isLoading: PropTypes.bool,

  blurHandler: PropTypes.func,
};

function ManualSetOrderLocationDialog(props) {
  const { classes, state, location, getLocalisationMessage } = props;
  return (
    <React.Fragment>
      <FormMapPinDialog
        countryCode={props.countryCode}
        open={state.showPickupMapDialog}
        initialValues={{ location }}
        mapCenter={getLatLng(location)}
        onRequestClose={() => {
          props.setState(fp.set("showPickupMapDialog", false));
        }}
        onSubmit={data => {
          props.setState(fp.set("showPickupMapDialog", false));
          props.change(props.fieldName, data.location);
        }}
      >
        <Paper elevation={2} className={classes.manualFieldsBox}>
          <FlexBox justify="space-around">
            <PageLoading isLoading={state.isLoading} />
            <FormTextField
              name="location.lat"
              label={getLocalisationMessage("latitude", "Latitude")}
              parseOnBlur={props.blurHandler}
            />

            <FormTextField
              name="location.lng"
              label={getLocalisationMessage("longitude", "Longitude")}
              parseOnBlur={props.blurHandler}
            />
          </FlexBox>
        </Paper>
      </FormMapPinDialog>
      <IconButton
        className={classes.iconButton}
        onClick={() => {
          props.setState(fp.set("showPickupMapDialog", true));
        }}
      >
        <Place />
      </IconButton>
    </React.Fragment>
  );
}

export default enhancer(ManualSetOrderLocationDialog);
