import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Marker, MarkerIcon } from "react-leflet-map-components";
import MarkerAccent from "../assets/marker-accent.png";
import MarkerPrimary from "../assets/marker-primary.png";

const enhancer = compose();

BrandMarker.propTypes = {
  size: PropTypes.number,
  accent: PropTypes.bool,

  position: PropTypes.object.isRequired,
  labelOriginX: PropTypes.number,
  labelOriginY: PropTypes.number,
};

BrandMarker.defaultProps = {
  size: 32,
  accent: true,
};

function BrandMarker(props) {
  const { size, accent, ...markerProps } = props;

  return (
    <Marker
      {...markerProps}
      icon={
        <MarkerIcon
          url={accent ? MarkerAccent : MarkerPrimary}
          anchor={{ x: 27, y: 55 }}
          popupAnchor={{ x: 0, y: 0 }}
          origin={{ x: 0, y: 0 }}
          labelOrigin={{ x: props.labelOriginX, y: props.labelOriginY }}
          size={{ width: 55, height: 55 }}
          scaledSize={{
            width: size,
            height: size,
          }}
        />
      }
    />
  );
}

export default enhancer(BrandMarker);
