import { OrderedSet } from "immutable";

export const DO_NOT_DELIVER = "do_not_deliver";
export const LEAVE_AT_DOOR = "leave_at_door";
export const LEAVE_WITH_CONCIERGE = "leave_with_concierge";

export default OrderedSet.of(
  DO_NOT_DELIVER,
  LEAVE_AT_DOOR,
  LEAVE_WITH_CONCIERGE,
);
