import fp from "lodash/fp";

const NA = "N/A";

const formatTimeSlotTime = fp.flow(fp.trim, v => v.slice(0, 5), v => v || NA);

export const formatTimeSlot = timeSlot =>
  timeSlot
    ? `${formatTimeSlotTime(timeSlot.get("start_time"))}-${formatTimeSlotTime(
        timeSlot.get("end_time"),
      )}`
    : "";
