import { api } from "./BaseApi";
import {
  API_ROOT_URL,
  API_V2_ROOT_URL,
} from "../../../shared/constants/ApiConstants";

const PACKAGES_PRICES_URL = `${API_ROOT_URL}/packages/prices`;

export const getPackagePrices = (request) =>
  api.getStream(PACKAGES_PRICES_URL, {
    params: request.setValue("service", "LOGISTICS").getDefinedValues(),
  });

const CUSTOMER_PACKAGES_PRICES_URL = `${API_V2_ROOT_URL}/customer/packages/prices`;

export const getPackagePricesStaringFrom = (request) =>
  api.getStream(CUSTOMER_PACKAGES_PRICES_URL, {
    params: request.getDefinedValues(),
  });

export const getCustomerPackagePrices = (
  serviceTypeId,
  weight,
  fromJurisdictionId,
  toJurisdictionId,
) =>
  api.get(CUSTOMER_PACKAGES_PRICES_URL, {
    params: {
      serviceTypeId,
      "dimensions.weight": weight,
      fromJurisdictionId,
      toJurisdictionId,
    },
  });
