import { Observable } from "rxjs";
import React from "react";
import { List, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, getContext, componentFromStream } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { AutoCompleteComponent } from "./FormAutoComplete";
import { isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { ACTIVE } from "../../constants/OverallStatus";

const baseFilter = new DataListFilter({ page: 0, size: 20, status: ACTIVE });

const parseInput = name => ({ name });
const formatOption = fp.get("name");
// const distinctBy = ({ countryId, input: { value } }) => ({ countryId, value });

const enhancer = compose(
  getContext({
    getCachedPostcode: PropTypes.func,
    getPostcodePredictions: PropTypes.func,
  }),
);

const NeighborhoodAutoComplete = enhancer(
  componentFromStream(propsStream => {
    const valueStream = propsStream
      .distinctUntilChanged(isEqualDataIn("input.value"))
      .switchMap(({ getCachedPostcode, input: { value } }) =>
        value && value.id && !value.name && getCachedPostcode
          ? getCachedPostcode(value.id)
              .map(item => ({ id: value.id, name: item.get("name") }))
              .catch(() => Observable.of(value))
              .startWith(value)
          : Observable.of(value),
      );

    const optionsStream = propsStream
      .map(v => ({
        countryId: v.countryId,
        cityId: v.cityId,
        name: v.input.value.name,
      }))
      .distinctUntilChanged(isEqualData)
      .withLatestFrom(propsStream)
      .map(([, props]) => props)
      .switchMap(
        ({
          getPostcodePredictions,
          countryId,
          cityId,
          includeUnknownNeighborhood,
          input: { value },
        }) =>
          getPostcodePredictions
            ? getPostcodePredictions(
                baseFilter
                  .setValue("country_id", countryId)
                  .setValue("city_id", cityId > 0 ? cityId : null)
                  .setValue(
                    "include_unknown_neighborhood",
                    includeUnknownNeighborhood,
                  )
                  .setSearch(value && value.name),
              )
                .map(fp.flow(fp.get("payload.data"), fp.toArray, fromJS))
                .catch(() => Observable.of(List()))
            : Observable.of(List()),
      )
      .distinctUntilChanged(isEqualData)
      .map(v => v.toJS());

    return propsStream.combineLatest(
      valueStream,
      optionsStream,
      (
        {
          readOnly,
          getCachedPostcode,
          getPostcodePredictions,
          countryId,
          cityId,
          ...props
        },
        value,
        options,
      ) => (
        <AutoCompleteComponent
          {...props}
          options={options}
          filter={fp.stubTrue}
          parseInput={parseInput}
          formatOption={formatOption}
          input={{ ...props.input, value }}
          readOnly={!getCachedPostcode || !getPostcodePredictions || readOnly}
          disabled={!countryId || props.disabled}
        />
      ),
    );
  }),
);

FormNeighborhoodV2AutoComplete.propTypes = {
  name: PropTypes.string.isRequired,

  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  maxSearchResults: PropTypes.number,

  hintText: PropTypes.node,
  label: PropTypes.node,
  countryId: PropTypes.number,
  cityId: PropTypes.number,
  includeUnknownNeighborhood: PropTypes.bool,
};

FormNeighborhoodV2AutoComplete.defaultProps = {
  maxSearchResults: 10,
  cityId: 0,
  includeUnknownNeighborhood: false,
};

export default function FormNeighborhoodV2AutoComplete(props) {
  return <Field {...props} component={NeighborhoodAutoComplete} />;
}
