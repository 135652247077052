import { OrderedSet } from "immutable";

export const BIKE = "bike";
export const SEDAN = "sedan";
export const VAN = "van";
export const MINIVAN = "minivan";
export const PANELVAN = "panelvan";
export const LIGHT_TRUCK = "light_truck";
export const REFRIGERATED_TRUCK = "refrigerated_truck";
export const RECOVERY_TRUCK = "recovery_truck";
export const TOWING = "towing";
export const RELOCATION = "relocation";

export default OrderedSet.of(
  BIKE,
  SEDAN,
  MINIVAN,
  PANELVAN,
  LIGHT_TRUCK,
  REFRIGERATED_TRUCK,
  RECOVERY_TRUCK,
  TOWING,
  RELOCATION,
);
