import React from "react";
import { Map, List } from "immutable";
import { mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import FlagIcon from "react-flag-kit/lib/CDNFlagIcon";
import FormAutoComplete from "./FormAutoComplete";
import { getCountries } from "../../api/shared/CountryApi";

const enhancer = mapPropsStream(propsStream => {
  const countriesStream = getCountries().startWith(List());

  return propsStream.combineLatest(countriesStream, (props, countries) => ({
    ...props,
    options: countries,
    disabled: countries.isEmpty() || props.disabled,
  }));
});

FormCountryAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,

  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  maxSearchResults: PropTypes.number,

  focus: PropTypes.bool,

  hintText: PropTypes.node,
  label: PropTypes.node,
};

FormCountryAutoComplete.defaultProps = { maxSearchResults: 10 };

const parseInput = name => Map({ name });
const formatOption = item => (item ? item.get("name") : null);
const renderOptionLeftIcon = item =>
  item ? <FlagIcon code={item.get("code")} /> : null;

function FormCountryAutoComplete(props) {
  return (
    <FormAutoComplete
      {...props}
      parseInput={parseInput}
      formatOption={formatOption}
      renderOptionLeftIcon={renderOptionLeftIcon}
      filter={FormAutoComplete.caseInsensitiveFilter}
    />
  );
}

export default enhancer(FormCountryAutoComplete);
