import { DASHBOARD_URL } from "./DashboardPathConstants";

export const SIGN_UP_PATH = "sign-up";
export const SIGN_UP_URL = `/${SIGN_UP_PATH}`;

export const FACEBOOK_SIGN_UP_PATH = "facebook-sign-up";
export const FACEBOOK_SIGN_UP_URL = `/${FACEBOOK_SIGN_UP_PATH}`;

export const PUBLIC_ORDER_TRACK_PATH = "track";
export const PUBLIC_ORDER_TRACK_URL = `/${PUBLIC_ORDER_TRACK_PATH}`;

export const PUBLIC_ORDER_TRACK_MAP_PATH = "track-map";
export const PUBLIC_ORDER_TRACK_MAP_URL = `/${PUBLIC_ORDER_TRACK_MAP_PATH}`;

export const PUBLIC_ORDER_CONFIRM_PATH = "delivery-confirm/:code";
export const PUBLIC_ORDER_CONFIRM_URL = `/${PUBLIC_ORDER_CONFIRM_PATH}`;

export const ORDER_LOCATION_PATH = "order-location/:code";

export const VERIFY_PHONE_NUMBER_PATH = "verify-phone-number";
export const VERIFY_PHONE_NUMBER_URL = `/${VERIFY_PHONE_NUMBER_PATH}`;

export const ORDER_LIST_PATH = "order/list";
export const ORDER_LIST_URL = `${DASHBOARD_URL}/order/list`;

export const ORDER_ITEM_PATH = "order/item/:id(/:tab)";
export const ORDER_ITEM_URL = `${DASHBOARD_URL}/order/item/`;

export const ORDER_ITEM_V2_PATH = "order/details/:id(/:tab)";
export const ORDER_ITEM_V2_URL = `${DASHBOARD_URL}/order/details/`;

export const ORDER_SORTING_PATH = "order-sorting";
export const ORDER_SORTING_URL = `${DASHBOARD_URL}/order-sorting`;

export const ORDER_RESCHEDULE_TIMESLOT_PATH =
  "order-update-address/:orderNumber";
export const ORDER_RESCHEDULE_TIMESLOT_URL = "/order-update-address/";

export const ORDER_PUBLIC_ADDRESS_VERIFICATION_PATH =
  "verify-address/:orderNumber";
export const ORDER_PUBLIC_ADDRESS_VERIFICATION_URL = "/verify-address/";

export const ORDER_PUBLIC_CUSTOMER_FEEDBACK_PATH =
  "order-feedback/:orderNumber";
export const ORDER_PUBLIC_CUSTOMER_FEEDBACK_URL = "/order-feedback/";

export const ORDER_RESCHEDULE_TIMESLOT_V2_PATH =
  "order-address-confirmation/:orderNumber";
export const ORDER_RESCHEDULE_TIMESLOT_V2_URL = "/order-address-confirmation/";

export const ORDER_CREATE_PATH = "order/create";
export const ORDER_CREATE_URL = `${DASHBOARD_URL}/${ORDER_CREATE_PATH}`;

export const ORDER_CREATE_NEW_PATH = "order/create/new";
export const ORDER_CREATE_NEW_URL = `${DASHBOARD_URL}/${ORDER_CREATE_NEW_PATH}`;

export const ORDER_CREATE_DEFAULT_PATH = "order/create";
export const ORDER_CREATE_DEFAULT_URL = `${DASHBOARD_URL}/${ORDER_CREATE_DEFAULT_PATH}`;

export const ORDER_CREATE_SUCCESS_PATH = "order/thank-you/:id";
export const ORDER_CREATE_SUCCESS_URL = `${DASHBOARD_URL}/order/thank-you/`;

export const ORDER_UPLOAD_PATH = "order/upload";
export const ORDER_UPLOAD_V2_PATH = "order/upload_v2";
export const ORDER_UPLOAD_URL = `${DASHBOARD_URL}/order/upload`;
export const ORDER_UPLOAD_V2_URL = `${DASHBOARD_URL}/order/upload_v2`;

// Finance
export const FINANCE_ORDER_LIST_PATH = "finance/order/list(/:codStatus)";
export const FINANCE_ORDER_LIST_URL = `${DASHBOARD_URL}/finance/order/list`;

export const FINANCE_WITHDRAW_ITEM_PATH = "finance/withdraw/item/:id";
export const FINANCE_WITHDRAW_ITEM_URL = `${DASHBOARD_URL}/finance/withdraw/item/`;

export const FINANCE_WITHDRAW_LIST_PATH =
  "finance/withdraw/list(/:transferStatus)";
export const FINANCE_WITHDRAW_LIST_URL = `${DASHBOARD_URL}/finance/withdraw/list`;

export const FINANCE_BALANCE_PATH = "finance/balance";
export const FINANCE_BALANCE_URL = `${DASHBOARD_URL}/finance/balance`;

export const FINANCE_TRANSACTIONS_PATH = "finance/transactions";
export const FINANCE_TRANSACTIONS_URL = `${DASHBOARD_URL}/finance/transactions`;

// Address Book
export const ADDRESS_BOOK_PATH = "address-book";
export const ADDRESS_BOOK_URL = `${DASHBOARD_URL}/${ADDRESS_BOOK_PATH}`;

export const ADDRESS_BOOK_CREATE_PATH = "address-book/create";
export const ADDRESS_BOOK_CREATE_URL = `${DASHBOARD_URL}/${ADDRESS_BOOK_CREATE_PATH}`;
export const ADDRESS_BOOK_ITEM_PATH = "address-book/item/:id";
export const ADDRESS_BOOK_ITEM_URL = `${DASHBOARD_URL}/address-book/item/`;

// Address Book V2
export const ADDRESS_BOOK_NEW_PATH = "address-book-new";
export const ADDRESS_BOOK_NEW_URL = `${DASHBOARD_URL}/${ADDRESS_BOOK_NEW_PATH}`;

export const ADDRESS_BOOK_CREATE_NEW_PATH = "address-book-new/create";
export const ADDRESS_BOOK_CREATE_NEW_URL = `${DASHBOARD_URL}/${ADDRESS_BOOK_CREATE_NEW_PATH}`;

export const ADDRESS_BOOK_ITEM_NEW_PATH = "address-book-new/item/:id";
export const ADDRESS_BOOK_ITEM_NEW_URL = `${DASHBOARD_URL}/address-book-new/item/`;

// Communication Settings
export const COMMUNICATION_SETTINGS_LIST_PATH = "communication-settings/list";
export const COMMUNICATION_SETTINGS_CREATE_PATH =
  "communication-settings/create";
export const COMMUNICATION_SETTINGS_ITEM_PATH =
  "communication-settings/item/:id";

export const COMMUNICATION_SETTINGS_LIST_URL = `${DASHBOARD_URL}/communication-settings/list`;
export const COMMUNICATION_SETTINGS_CREATE_URL = `${DASHBOARD_URL}/communication-settings/create`;
export const COMMUNICATION_SETTINGS_ITEM_URL = `${DASHBOARD_URL}/communication-settings/item/`;

// Receiving orders
export const RECEIVING_ORDERS_PATH = "receiving-orders";

export const RECEIVING_ORDERS_URL = `${DASHBOARD_URL}/receiving-orders`;

// Bank

export const BANK_LIST_PATH = "bank/list";
export const BANK_LIST_URL = `${DASHBOARD_URL}/bank/list`;

// Dashboard
export const CHARTS_PATH = "charts";
export const CHARTS_URL = `${DASHBOARD_URL}/charts`;

// Postcodes
export const ORDERS_PER_POSTCODES_PATH = "orders-per-postcodes";
export const ORDERS_PER_POSTCODES_URL = `${DASHBOARD_URL}/orders-per-postcodes`;
export const ORDERS_PER_POSTCODES_PATH_2 = "orders-per-postcodes(/:lat)(/:lng)";
export const ORDERS_PER_POSTCODES_URL_2 = `${DASHBOARD_URL}/orders-per-postcodes/`;
