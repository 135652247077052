import { Observable } from "rxjs";
import fp from "lodash/fp";
import {
  compose,
  createEventHandler,
  mapPropsStream,
  withState,
} from "recompose";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import { isEqualData } from "../../helpers/DataUtils";
import { mergeSideEffectStreams } from "../../helpers/StreamUtils";

const selectInputText = input => {
  const contentLength = fp.size(input.value);

  if (contentLength > 0) {
    input.setSelectionRange(0, contentLength);
  }
};

const getTarget = fp.get("target");
const getTagName = fp.flow(fp.get("tagName"), fp.toLower);
const isTextInput = fp.flow(
  x => x.getAttribute("type"),
  x =>
    x !== "button" &&
    x !== "checkbox" &&
    x !== "file" &&
    x !== "image" &&
    x !== "radio" &&
    x !== "reset" &&
    x !== "submit",
);

const notTextField = fp.flow(getTarget, x => {
  const tagName = getTagName(x);

  return tagName === "input" ? !isTextInput(x) : tagName !== "textarea";
});

const enhancer = compose(
  withState("state", "setState", { value: "" }),
  mapPropsStream(propsStream => {
    const { stream: refStream, handler: ref } = createEventHandler();
    const { stream: onKeyUpStream, handler: onKeyUp } = createEventHandler();
    const { stream: onFocusStream, handler: onFocus } = createEventHandler();

    const sideEffectsStream = mergeSideEffectStreams(
      propsStream
        .map(fp.update("focus", Boolean))
        .distinctUntilKeyChanged("focus")
        .filter(fp.get("focus"))
        .combineLatest(refStream.map(fp.get("input")).filter(Boolean))
        .delay(200)
        .do(([, input]) => input.focus())
        .switchMap(([props, input]) => {
          const keyPressStream = Observable.fromEvent(
            document,
            "keypress",
          ).filter(notTextField);

          return keyPressStream
            .map(fp.get("key"))
            .buffer(keyPressStream.debounceTime(50))
            .filter(x => x.length > 1 && fp.last(x) === "Enter")
            .map(x => fp.initial(x).join(""))
            .do(value => {
              input.focus();
              // eslint-disable-next-line no-param-reassign
              input.value = value;
              selectInputText(input);
              props.onChange(value);
              props.setState({ value });
            });
        }),
      onKeyUpStream
        .filter(fp.matches({ keyCode: 13 }))
        .map(fp.get("target"))
        .withLatestFrom(propsStream)
        .do(([input, props]) => {
          selectInputText(input);
          props.onChange(input.value);
        })
        .do(([input, props]) => {
          if (props.clearAfterEnter) {
            // eslint-disable-next-line no-param-reassign
            input.value = "";
          }
        }),
      onFocusStream.map(fp.get("target")).do(selectInputText),
    );

    return propsStream
      .merge(sideEffectsStream)
      .distinctUntilChanged(isEqualData)
      .map(({ focus, state, setState, ...props }) => ({
        ...props,

        ref,
        onKeyUp: event => {
          onKeyUp(event);
          props.onKeyUp(event);
        },
        onFocus: event => {
          onFocus(event);
          props.onFocus(event);
        },

        value: state.value,
        onChange: event => setState({ value: event.target.value }),
      }));
  }),
);

const ScannerTextField = enhancer(TextField);

ScannerTextField.propTypes = {
  focus: PropTypes.bool,
  disabled: PropTypes.bool,
  clearAfterEnter: PropTypes.bool,

  onFocus: PropTypes.func,
  onBlur: PropTypes.func,

  onKeyUp: PropTypes.func,
  onChange: PropTypes.func,
  variant: PropTypes.string,
  size: PropTypes.string,
  inputRef: PropTypes.any,
};

ScannerTextField.defaultProps = {
  onFocus: fp.noop,
  onBlur: fp.noop,

  onKeyUp: fp.noop,
  onChange: fp.noop,
  variant: "outlined",
  clearAfterEnter: false,
};

export default ScannerTextField;
